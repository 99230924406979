import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import CreateorEdituser from "pages/Mylayout/CreateorEdituser"
import StickyHeaders from "pages/Mylayout/StickyHeaders"
import ProductList from "pages/Mylayout/ProductList"
import UserList from "pages/Mylayout/UserList"
import CreateorEditProduct from "pages/Mylayout/CreateorEditProduct"
import WoConfirmationPlay from "pages/Mylayout/WoConfirmationPlay"
import UserDeviceAuthoriztionList from "pages/Mylayout/UserDeviceAuthoriztionList"
import AuthPendingReq from "pages/Mylayout/AuthPendingReq"
import LocationApprovalReq from "pages/Mylayout/LocationApprovalReq"
import Assignproductandheaders from "pages/Mylayout/assignproductandheaders"
import Blockedcustomerlist from "pages/Mylayout/blockedcustomerlist"
import Blockedcustomer from "pages/Mylayout/blockedcustomer"
import Breaktimeconfigurtaion from "pages/Mylayout/breaktimeconfigurtaion"
import Presentyworksummeryreport from "pages/Mylayout/presentyworksummeryreport"
import Datewiseproductstatisticsreport from "pages/Mylayout/datewiseproductstatisticsreport"
import Logreport from "pages/Mylayout/Logreport"
import Complaintoverheadreport from "pages/Mylayout/Complaintoverheadreport"
import FeedbackQuestion from "pages/Mylayout/FeedbackQuestion"
import DailyWorkingHoursreport from "pages/Mylayout/DailyWorkingHoursreport"
import ComplaintReportforproductReaserach from "pages/Mylayout/ComplaintReportforproductReaserach"
import ProductWiseSalesReport from "pages/Mylayout/ProductWiseSalesReport"
import ProductWiseComplaintReport from "pages/Mylayout/ProductWiseComplaintReport"
import ImportCustomerdata from "pages/Mylayout/ImportCustomerdata"
// import telecallerassign from "pages/Mylayout/telecallerassign"
import FollowupReason from "pages/Mylayout/FollowupReason"
import Telecallerassign from "pages/Mylayout/telecallerassign"
import Enquirycalllogreport from "pages/Mylayout/Enquirycalllogreport"
import Lostreport from "pages/Mylayout/Lostreport"
import Feedbackreport from "pages/Mylayout/Feedbackreport"
import Workorderfeedback from "pages/Mylayout/Workorderfeedback"
import Callogreport from "pages/Mylayout/Callogreport"
import Manualchallanworkorders from "pages/Mylayout/Manualchallanworkorders"
import Reportmanualchallanworkorders from "pages/Mylayout/Reportmanualchallanworkorders"
import WorkorderpostponeReport from "pages/Mylayout/WorkorderpostponeReport"
import Uploadorchangeorgdata from "pages/Mylayout/uploadorchangeorgdata"
import Systemconfigurationparameters from "pages/Mylayout/Systemconfigurationparameters"
import Schedulerassignment from "pages/Mylayout/Schedulerassignment"
import Mangaerblockedcustomerlist from "pages/ManagerPages/Mangaerblockedcustomerlist"
import Managertodaycompletedwordkorders from "pages/ManagerPages/Managertodaycompletedwordkorders"
import SendcallbackRequest from "pages/ManagerPages/SendcallbackRequest"
import AssignColdCalling from "pages/ManagerPages/AssignColdCalling"
import AssignFeedbackCalls from "pages/ManagerPages/AssignFeedbackCalls"
import Managerlivecallboard from "pages/ManagerPages/Managerlivecallboard"
import Attentedcallbytelecallers from "pages/ManagerPages/Attentedcallbytelecallers"
import ColdCallingStatus from "pages/ManagerPages/ColdCallingStatus"
import ColdCallingslotwisestatus from "pages/ManagerPages/ColdCallingslotwisestatus"
import ColdCallingDashboardoperation from "pages/ManagerPages/ColdCallingDashboardoperation"
import Feedbackcallingdashboardforoperation from "pages/ManagerPages/Feedbackcallingdashboardforoperation"
import MaintainanceContactpendingexecution from "pages/ManagerPages/MaintainanceContactpendingexecution"
import Blockedcustomermissedcalls from "pages/ManagerPages/Blockedcustomermissedcalls"
import ColdCallUploads from "pages/ColdCalls/Model/ColdCallUploads"
import CurrentCallBoardStatus from "pages/ColdCalls/Reports/CurrentCallBoardStatus"
import VerfierWiseCurrentCallingStatus from "pages/ColdCalls/Reports/VerfierWiseCurrentCallingStatus"
import CallAvailability from "pages/Users/CallAvailability"
import ChangeUserCallAvailability from "pages/Users/ChangeUserCallAvailability"
import UserWiseDurationReport from "pages/Users/Report/UserWiseDurationReport"

// function t(test) {
//   console.log(test)
// }

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // Cold Call
  { path: "/upload", component: <ColdCallUploads /> },
  { path: "/CurrentCallBoardStatus", component: <CurrentCallBoardStatus /> },
  { path: "/verifierWiseCurrentCallingStatus", component: <VerfierWiseCurrentCallingStatus /> },

  //Users
  { path: "/callAvailability", component: <CallAvailability /> },
  { path: "/ChangeUserCallAvailability/:empId", component: <ChangeUserCallAvailability /> },
  { path: "/UserWiseDurationReport", component: <UserWiseDurationReport /> },



  { path: "/userlist", component: <UserList /> },
  { path: "/createoredituser", component: <CreateorEdituser /> },
  { path: "/stickyheaders", component: <StickyHeaders /> },
  { path: "/productlist", component: <ProductList /> },
  { path: "/createoreditproduct", component: <CreateorEditProduct /> },
  { path: "/woconfirmationplay", component: <WoConfirmationPlay /> },
  {
    path: "/followupreason",
    component: <FollowupReason />,
  },
  {
    path: "/userdeviceauthoriztionlist",
    component: <UserDeviceAuthoriztionList />,
  },
  {
    path: "/authpendingreq",
    component: <AuthPendingReq />,
  },
  {
    path: "/locationapprovalreq",
    component: <LocationApprovalReq />,
  },
  {
    path: "/telecallerassign",
    component: <Telecallerassign />,
  },

  {
    path: "/blockedcustomerlist",
    component: <Blockedcustomerlist />,
  },

  {
    path: "/blockedcustomer",
    component: <Blockedcustomer />,
  },

  {
    path: "/breaktimeconfigurtaion",
    component: <Breaktimeconfigurtaion />,
  },
  {
    path: "/presentyworksummeryreport",
    component: <Presentyworksummeryreport />,
  },
  {
    path: "/datewiseproductstatisticsreport",
    component: <Datewiseproductstatisticsreport />,
  },

  {
    path: "/enquirycalllogreport",
    component: <Enquirycalllogreport />,
  },

  {
    path: "/complaintoverheadreport",
    component: <Complaintoverheadreport />,
  },

  {
    path: "/lostreport",
    component: <Lostreport />,
  },
  {
    path: "/dailyWorkingHoursreport",
    component: <DailyWorkingHoursreport />,
  },
  {
    path: "/complaintreportforproductreaserach",
    component: <ComplaintReportforproductReaserach />,
  },
  {
    path: "/productwisesalesreport",
    component: <ProductWiseSalesReport />,
  },

  {
    path: "/productwisecomplaintreport",
    component: <ProductWiseComplaintReport />,
  },

  {
    path: "/workorderpostponeReport",
    component: <WorkorderpostponeReport />,
  },
  {
    path: "/importcustomerdata",
    component: <ImportCustomerdata />,
  },
  {
    path: "/uploadorchangeorgdata",
    component: <Uploadorchangeorgdata />,
  },
  {
    path: "/systemconfigurationparameters",
    component: <Systemconfigurationparameters />,
  },
  {
    path: "/feedbackquestion",
    component: <FeedbackQuestion />,
  },
  // addd feed back question pending
  {
    path: "/feedbackreport",
    component: <Feedbackreport />,
  },
  {
    path: "/workorderfeedback",
    component: <Workorderfeedback />,
  },

  // Scheduler assignment pending

  {
    path: "/schedulerassignment",
    component: <Schedulerassignment />,
  },

  {
    path: "/callogreport",
    component: <Callogreport />,
  },

  {
    path: "/manualchallanworkorders",
    component: <Manualchallanworkorders />,
  },

  {
    path: "/reportmanualchallanworkorders",
    component: <Reportmanualchallanworkorders />,
  },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  {
    path: "/assignproductandheaders",
    component: <Assignproductandheaders />,
  },

  {
    path: "/assignproductandheaders",
    component: <Assignproductandheaders />,
  },

  {
    path: "logreport",
    component: <Logreport />,
  },
  // Manager Start
  {
    path: "/mangaerblockedcustomerlist",
    component: <Mangaerblockedcustomerlist />,
  },
  {
    path: "/managertodaycompletedwordkorders",
    component: <Managertodaycompletedwordkorders />,
  },
  {
    path: "/sendcallbackRequest",
    component: <SendcallbackRequest />,
  },

  {
    path: "/assignColdCalling",
    component: <AssignColdCalling />,
  },

  {
    path: "/assignFeedbackCalls",
    component: <AssignFeedbackCalls />,
  },

  {
    path: "/managerlivecallboard",
    component: <Managerlivecallboard />,
  },

  {
    path: "/attentedcallbytelecallers",
    component: <Attentedcallbytelecallers />,
  },

  {
    path: "/coldcallingStatus",
    component: <ColdCallingStatus />,
  },

  {
    path: "/coldcallingslotwisestatus",
    component: <ColdCallingslotwisestatus />,
  },

  {
    path: "/coldcallingdashboardoperation",
    component: <ColdCallingDashboardoperation />,
  },

  {
    path: "/feedbackcallingdashboardforoperation",
    component: <Feedbackcallingdashboardforoperation />,
  },

  {
    path: "/maintainancecontactpendingexecution",
    component: <MaintainanceContactpendingexecution />,
  },

  {
    path: "/blockedcustomermissedcalls",
    component: <Blockedcustomermissedcalls />,
  },




  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/createoredituser" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
