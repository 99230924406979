import axios from "axios";
import CryptoJS from "crypto-js";


export const getUserIP = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
    }
};

// Helper functions to get device and location data
export const getUserLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: Number(position.coords.latitude.toFixed(7)), // 7 decimal places
                        long: Number(position.coords.longitude.toFixed(7)), // 7 decimal places
                    });
                },
                (error) => {
                    console.error("Error retrieving location:", error.message);
                    resolve({ lat: 0, long: 0 }); // Default location if denied
                }
            );
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
};

export const getDeviceDetails = () => {
    const userAgent = navigator.userAgent;

    let browserName = "Unknown";
    let deviceType = /Mobi|Android/i.test(userAgent) ? "Mobile" : "Desktop";
    let deviceOS = /Windows/i.test(userAgent) ? "Win32" : "Unknown";
    let browserVersion = "Unknown Version";
    let renderingEngine = "Blink"; // Typically used for Chromium-based browsers
    let deviceOSVersion = "Unknown OS Version"; // You can modify this to extract version if needed

    if (/Chrome/i.test(userAgent) && /Edg/i.test(userAgent)) {
        // Edge browser
        browserName = "Microsoft Edge";
    } else if (/Chrome/i.test(userAgent)) {
        // Google Chrome browser
        browserName = "Google Chrome";
    } else if (/Firefox/i.test(userAgent)) {
        // Firefox browser
        browserName = "Mozilla Firefox";
    } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
        // Safari browser
        browserName = "Safari";
    } else if (/MSIE|Trident/i.test(userAgent)) {
        // Internet Explorer browser
        browserName = "Internet Explorer";
    }

    return {
        deviceType,
        deviceOS,
        deviceOSVersion,
        browserName,
        browserVersion, // Simplified, could use a library for more accuracy
        renderingEngine,
        browserUserAgent: userAgent,
    };
};


export const generateDeviceSignature = (data) => {
    return CryptoJS.SHA256(JSON.stringify(data)).toString();
};
