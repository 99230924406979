// src/components/filter.
import PropTypes from "prop-types"
import * as Yup from "yup"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"

import { useFormik } from "formik"
import Select from "react-select"
import React, { useMemo, useState } from "react"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

//Import Breadcrumb
const AssignFeedbackCalls = () => {
  //Horizontal form
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  const columns = useMemo(
    () => [
      {
        header: "W/O Type",
        accessorKey: "wotype",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Play Time (Before)",
        accessorKey: "ptime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Unit",
        accessorKey: "unit",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      wotype: "One Time",
      ptime: "24",
      unit: "Hrs.",
    },
    {
      wotype: "Contract",
      ptime: "18",
      unit: "Hrs.",
    },
    {
      wotype: "Complaint",
      ptime: "12",
      unit: "Hrs.",
    },
  ]

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      // label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Assign Feedback Calls" />
          <Row md={2} className="mt-5">
            <Col md={{ span: 1, offset: 3 }}>
              <Card>
                <CardBody>
                  <div className="container-fluid">
                    {/* <CardTitle className="mb-4">
                      Horizontal form Layout
                    </CardTitle> */}
                    <Form onSubmit={horizontalformik.handleSubmit}>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Select Telecaller
                        </Label>
                        <Col sm={9}>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Select Filed Executive
                        </Label>
                        <Col sm={9}>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Select Product
                        </Label>
                        <Col sm={9}>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Form Date
                        </Label>
                        <Col sm={9}>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          To Date
                        </Label>
                        <Col sm={9}>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <CardTitle className="mb-4">
                        Target: 40 W/O Feedback Calls
                      </CardTitle>

                      <Row className="justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Assign Calls
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}
AssignFeedbackCalls.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AssignFeedbackCalls
