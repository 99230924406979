// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

const Managertodaycompletedwordkorders = () => {
  const columns = useMemo(
    () => [
      {
        header: "Date & Time",
        accessorKey: "datetime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Location",
        accessorKey: "loc",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Primary Mobile No.",
        accessorKey: "primobno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Season Dry Mobile Number",
        accessorKey: "seasonmobno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product",
        accessorKey: "product",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Filed Executive",
        accessorKey: "fieldexe",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Type",
        accessorKey: "wotype",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount Rs.",
        accessorKey: "amt",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Status",
        accessorKey: "wostatus",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Payment Status",
        accessorKey: "paysta",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Payment Type",
        accessorKey: "paytype",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      datetime: "12:30 AM",
      name: "Anuja Power",
      address: "301, B wing Kimaya Sociaty Warje 41105",
      loc: "Warje",
      primobno: "8789876777",
      seasonmobno: "876656667",
      product: "Product A",
      fieldexe: "Piyush Wani",
      wotype: "Complaint",
      tele: "Swara Date",
      amt: "3000",
      wostatus: "Pending",
      paysta: "Not Recived",
      paytype: "Cash",
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title="Tables"
          breadcrumbItem="Today Complete Work Orders"
        />
        <Card>
          <CardBody className="border-bottom">
            <TableContainer
              columns={columns}
              data={data || []}
              isPagination={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Managertodaycompletedwordkorders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Managertodaycompletedwordkorders
