import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container, UncontrolledTooltip } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from 'components/Common/TableContainer';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getUserListForCallAvailabilityAPIURL } from 'BaseComponentAPI/CallAvailability';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

const CallAvailability = () => {
    const authUser = useSelector(state => state.Login);
    // console.log(JSON.stringify(authUser))

    const navigate = useNavigate();

    document.title = "Dashboard | Skote - React Admin & Dashboard Template";


    const columns = useMemo(
        () => [
            {
                header: "User Name",
                accessorKey: "userName",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "User Role",
                accessorKey: "userRole",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Department",
                accessorKey: "department",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Is Available For Calling",
                accessorKey: "isAvailableForCalling",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Is Live Access",
                accessorKey: "isLiveAccess",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Is System Access",
                accessorKey: "isSystemAccess",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Is Cold Call Access",
                accessorKey: "isColdCallAccess",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Action",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/ChangeUserCallAvailability/${cellProps.row.original.userUUID}`);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target={`edittooltip-${cellProps.row.original.id}`}
                                        timeout={150}
                                    >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>

                            </li>

                        </ul>
                    );
                },
            },
        ],
        []
    );


    const [data, setData] = useState([]);


    const [pageNo, setPageNo] = useState(1);  // Track the current page number
    const [pageSize, setPageSize] = useState(10);  // Track page size
    const [totalPages, setTotalPages] = useState('');

   

    useEffect(() => {
        getData();
    }, [authUser, pageNo, pageSize]);

    const getData = async () => {
        if (authUser.headers && Object.keys(authUser.headers).length > 0) {
            // console.log(JSON.stringify(authUser.headers))
            // console.log(`${getUserListForCallAvailabilityAPIURL}?userUUID=${authUser.headers.userUUID}&isPagination=1&pageNo=${pageNo}&perPageRec=${pageSize}`)
            try {
                const response = await axios.get(
                    `${getUserListForCallAvailabilityAPIURL}?userUUID=${authUser.headers.userUUID}&isPagination=1&pageNo=${pageNo}&perPageRec=${pageSize}`,
                    {
                        headers: { ...authUser.headers },
                    }
                );

                if (response.data.responseMsg === "Success") {
                    setData(response.data.data.items);
                    setTotalPages(response.data.data.totalPages)
                }
                else {
                    console.error('API Response Error:', response.data.responseMsg);
                }
            } catch (error) {
                console.error('Error fetching data:', JSON.stringify(error));
            }
        }
    };

    const handlePageChange = (newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Users" breadcrumbItem="Call Availability" />
                    <Card>
                        <CardBody>
                            {data && (
                                <div className='mt-3'>
                                    {/* <div className="d-flex align-items-center justify-content-end">
                                        <div className="flex-shrink-0">
                                            <Link
                                                to="#"
                                                // onClick={() => setModal(true)}
                                                className="btn btn-primary me-1"
                                            >
                                                Export Excel
                                            </Link>
                                        </div>
                                    </div> */}
                                    <TableContainer
                                        columns={columns}
                                        data={data || []}
                                        isGlobalFilter={true}
                                        isPagination={true}
                                        SearchPlaceholder="Search..."
                                        isCustomPageSize={true}
                                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        pagination="pagination"
                                        getRowProps={row => ({
                                            style: {
                                                color: row.original.isRecAvailable == 1 ? "grey" : "transparent",
                                            },
                                        })}
                                        onPageChange={handlePageChange} // Pass page change handler
                                        onPageSizeChange={handlePageChange} // Pass page size change handler
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        totalPages={totalPages}
                                    />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <ToastContainer position="top-right" theme="colored" />
        </React.Fragment>
    )
}

CallAvailability.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CallAvailability);
