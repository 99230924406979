import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classname from "classnames";

// i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { useSelector } from "react-redux";

const newMenuList = [
  {
    "moduleUUID": "11efc5bf-26f8-d91d-9d30-22f17ecb52c5",
    "menuUUID": "e9f1172d-c5be-11ef-9d30-22f17ecb52c5",
    "label": "Reports",
    "icon": "Cold Calls_icon",
    "route": "/reports",
    "canRead": 1,
    "canCreate": 1,
    "canChange": 1,
    "subMenu": [
      {
        "moduleUUID": "11efc5bf-9ec4-def9-9d30-22f17ecb52c5",
        "menuUUID": "e9f1172d-c5be-11ef-9d30-22f17ecb52c5",
        "label": "Current Call Board Status",
        "icon": "upload_calls_icon",
        "route": "/CurrentCallBoardStatus",
        "canRead": 1,
        "canCreate": 1,
        "canChange": 0,
        "subMenu": []
      },
      {
        "moduleUUID": "11efc5bf-9ec4-def9-9d30-22f17ecb52c5",
        "menuUUID": "e9f1172d-c5be-11ef-9d30-22f17ecb52c5",
        "label": "Verifier Wise Current Calling Status",
        "icon": "upload_calls_icon",
        "route": "/verifierWiseCurrentCallingStatus",
        "canRead": 1,
        "canCreate": 1,
        "canChange": 0,
        "subMenu": []
      },
      {
        "moduleUUID": "11efc5bf-9ec4-def9-9d30-22f17ecb52c5",
        "menuUUID": "e9f1172d-c5be-11ef-9d30-22f17ecb52c5",
        "label": " User Wise Duration Report",
        "icon": "upload_calls_icon",
        "route": "/UserWiseDurationReport",
        "canRead": 1,
        "canCreate": 1,
        "canChange": 0,
        "subMenu": []
      },

    ]
  }
]

const Navbar = (props) => {
  const authUser = useSelector(state => state.Login);
  // console.log(JSON.stringify(authUser))

  const navigate = useNavigate();
  const location = useLocation();
  // const newMenuListDta = authUser.userData.concat(newMenuList)
  // console.log(JSON.stringify(newMenuListDta))
  const sessionData = authUser.userData || []

  const [menuStates, setMenuStates] = useState({});

  useEffect(() => {
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");

    // Remove active class from all items
    removeActivation(items);

    // Find the matching menu item based on the current pathname
    let matchingMenuItem = null;
    for (let i = 0; i < items.length; i++) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    // If a matching item is found, activate the item and its parents
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [location.pathname]);

  const removeActivation = (items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const parent = item.parentElement;

      if (item.classList.contains("active")) {
        item.classList.remove("active");
      }

      if (parent && parent.classList.contains("active")) {
        parent.classList.remove("active");
      }
    }
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");

    // Traverse up the DOM and add the active class to parent li elements
    let parent = item.parentElement;
    while (parent) {
      if (parent.tagName === "LI") {
        parent.classList.add("active");
      }
      parent = parent.parentElement;
    }
  };

  const toggleMenu = (menuKey) => {
    setMenuStates((prevState) => ({
      ...prevState,
      [menuKey]: !prevState[menuKey],
    }));
  };


  const handleMenuClick = (menuKey, event) => {
    event.stopPropagation();
    // Toggle the state of the top-level menu
    toggleMenu(menuKey);

    // Activate the clicked menu item and its parents
    const clickedItem = event.currentTarget;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");

    // Remove previous active states
    removeActivation(items);

    // Activate the clicked item and its parents
    activateParentDropdown(clickedItem);
  };

  const handleSubMenuClick = (menuKey, event, subMenuRoute) => {
    event.stopPropagation(); // Prevent submenu from closing when a submenu item is clicked
    const clickedItem = event.currentTarget;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");

    // toggleMenu(menuKey);

    // Remove previous active states
    removeActivation(items);

    // Activate the clicked item and its parents
    activateParentDropdown(clickedItem);

    // Update active class for the submenu item
    const submenuItems = document.querySelectorAll('.dropdown-item');
    submenuItems.forEach((item) => {
      if (item.getAttribute('href') === subMenuRoute) {
        item.classList.add('active'); // Add active class to clicked submenu item
      } else {
        item.classList.remove('active'); // Remove active class from other submenu items
      }
    });
  };

  const renderMenu = (menu) => {
    if (!menu.canRead) return null;

    return (
      <React.Fragment key={menu.menuUUID}>
        <Link
          to={menu.route || "#"}
          className={classname("nav-link", {
            "dropdown-toggle": menu.subMenu.length > 0,
          })}
          onClick={(e) => handleMenuClick(menu.menuUUID, e)}
        >
          <i className={`bx bx-${menu.icon} me-2`}></i>
          {menu.label}
          {menu.subMenu.length > 0 && <div className="arrow-down"></div>}
        </Link>
        {menu.subMenu.length > 0 && (
          <div className={classname("dropdown-menu", { "show": menuStates[menu.menuUUID] })}>
            {menu.subMenu.map((subMenu, index) => (
              <div key={index} className="dropdown"> {/* Concatenate parent and submenu UUIDs */}
                <Link
                  to={subMenu.route}
                  className={classname("dropdown-item")}
                  onClick={(e) => handleSubMenuClick(subMenu.menuUUID, e, subMenu.route)}
                >
                  <i className={`bx ${subMenu.icon || ""} me-2`}></i>
                  {subMenu.label}
                </Link>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };



  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {sessionData.map((module, index) => (
                  <li key={index} className="nav-item dropdown">
                    {renderMenu(module)}
                  </li>
                ))}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(mapStatetoProps, {})(withTranslation()(Navbar));
