// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

const Attentedcallbytelecallers = () => {
  const columns = useMemo(
    () => [
      {
        header: "Sr. No.",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: " Mobile No.",
        accessorKey: "mobno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Missed Call Date",
        accessorKey: "misseddate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Missed Call Time",
        accessorKey: "missedtime",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Verfier TAT",
        accessorKey: "veritat",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "TC TAT",
        accessorKey: "tctat",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Old TC",
        accessorKey: "oldtc",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Old Header",
        accessorKey: "oldhead",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Attended By",
        accessorKey: "attend",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product",
        accessorKey: "pro",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "New Header",
        accessorKey: "newhead",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action Date",
        accessorKey: "acdate",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      srno: "1",
      name: "Anuja Power",
      mobno: "7656565555",
      misseddate: "27-02-2024",
      missedtime: "09:10 PM",
      veritat: "01:00",
      tctat: "05:00",
      oldtc: "Piyush Wani",
      oldhead: "New Enq",
      attend: "Swara Date",
      pro: "Product A",
      amount: "2700",
      newhead: "W/O Placed",
      acdate: "22-01-24",
    },
  ]

  const veridata = useMemo(
    () => [
      {
        header: "Sr. No.",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: " Mobile No.",
        accessorKey: "mobno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Missed Call Date",
        accessorKey: "misseddate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Missed Call Time",
        accessorKey: "missedtime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Verification Time",
        accessorKey: "veritime",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Verfier TAT",
        accessorKey: "veritat",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Other Business Category",
        accessorKey: "otherbsns",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const veridatacall = [
    {
      srno: "1",
      name: "Anuja Power",
      mobno: "9876788888",
      misseddate: "26-04-2014",
      missedtime: "09:1 AM",
      veritime: "09:1 AM",
      veritat: "01:00",
      otherbsns: "Two Wheeler Serviceing",
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title="Tables"
          breadcrumbItem="Attended Call By Telecallers"
        />
        <Card>
          <CardBody className="border-bottom">
            <CardTitle className="mb-4">
              Attended Calls From Live Call Board
            </CardTitle>
            <TableContainer
              columns={columns}
              data={data || []}
              isPagination={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            <CardTitle className="mb-4">
              Verified Other Category Calls Live Call Board
            </CardTitle>
            <TableContainer
              columns={veridata}
              data={veridatacall || []}
              isPagination={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Attentedcallbytelecallers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Attentedcallbytelecallers
