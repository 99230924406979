import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "react-datepicker/dist/react-datepicker.css"; // Import Datepicker styles
import 'react-toastify/dist/ReactToastify.css';


// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { loginSuccess } from "store/actions"
import { useDispatch } from "react-redux"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    const getSessionData = sessionStorage.getItem("key-data")
    const getSessionHeaderData = sessionStorage.getItem("headers")
    const getSessionMenuData = sessionStorage.getItem("menuData")
    const getLocalData = localStorage.getItem("authUser")

    // console.log(getSessionMenuData)

    if (getSessionHeaderData || getSessionMenuData) {

      const localMenuData = (JSON.parse(getSessionMenuData));
      const localHeaderData = (JSON.parse(getSessionHeaderData));

      dispatch(loginSuccess({
        userData: localMenuData,
        headers: localHeaderData,
      }));
    }
    else {
      sessionStorage.removeItem("key-data")
      localStorage.removeItem("authUser")
      sessionStorage.removeItem("headers")
      sessionStorage.removeItem("menuData")

      // Redirect to login page
      navigate("/login")
    }


  }, [navigate]) // Add navigate as a dependency


  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>
                  {route.component}
                </Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>

    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
