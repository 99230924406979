import { BaseURl } from "./BaseApiUrl";

//https://testcockpit.p2k.in/api/userdetails/getUserListForCallsAvailability/v1/?userUUID=106c67c4-f015-11ee-91f2-b8ca3ad15bed&isPagination=1&pageNo=1&perPageRec=3
export const getUserListForCallAvailabilityAPIURL = BaseURl + "userdetails/getUserListForCallsAvailability/v1/";

//https://testcockpit.p2k.in/api/userdetails/getUserCallsAvailability/v1/?employeeUUID=721fb220-49be-11ef-bab4-22f17ecb52c5
export const getUserForChangeCallAvailabilityAPIURL = BaseURl + "userdetails/getUserCallsAvailability/v1/";

//https://testcockpit.p2k.in/api/userdetails/changeUserCallAvailability/v1/
export const updateUserForCallAvailabilityAPIURL = BaseURl + "userdetails/changeUserCallAvailability/v1/";
