import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container, UncontrolledTooltip } from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from 'components/Common/TableContainer';
import { Link } from 'react-router-dom';
import { getVerifierWiseCurrentCallingStatus } from 'BaseComponentAPI/VerifierwiseCurrentCallingAPI';
import { useSelector } from 'react-redux';
import axios from 'axios';

const VerfierWiseCurrentCallingStatus = () => {
    const authUser = useSelector(state => state.Login);
    // console.log(JSON.stringify(authUser))

    document.title = "Dashboard | Skote - React Admin & Dashboard Template";

    const columns = useMemo(
        () => [
            {
                header: "Verifier Name",
                accessorKey: "userName",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Current Status",
                accessorKey: "currentLoginStatus",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Assignment Status",
                accessorKey: "assignmentStatus",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Board Name",
                accessorKey: "callBoardName",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Process Cnt",
                accessorKey: "callProcessCnt",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Connected Calls Cnt",
                accessorKey: "connectedCallsCnt",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Converted Laed Cnt",
                accessorKey: "convertedLeadCnt",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Time Duration",
                accessorKey: "callTimeDuration",
                enableColumnFilter: false,
                enableSorting: true,
            },


            {
                header: "Action",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link
                                    to="/job-details"
                                    className="btn btn-sm btn-soft-primary"
                                    id={`viewtooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-eye-outline" />
                                </Link>
                            </li>
                            <UncontrolledTooltip
                                placement="top"
                                target={`viewtooltip-${cellProps.row.original.id}`}
                                transition={{ timeout: 0 }} // Add this line
                            >
                                Show Details
                            </UncontrolledTooltip>
                        </ul>
                    )
                },
            },
        ],
        []
    )

    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(1);  // Track the current page number
    const [pageSize, setPageSize] = useState(10);  // Track page size
    const [totalPages, setTotalPages] = useState('');

    const handlePageChange = (newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
    };


    useEffect(() => {
        getData();
    }, [authUser]);

    const getData = async () => {
        if (authUser.headers && Object.keys(authUser.headers).length > 0) {
            // console.log(`API URL: ${getVerifierWiseCurrentCallingStatus}?userUUID=${authUser.headers.userUUID}`);

            try {
                const response = await axios.get(
                    `${getVerifierWiseCurrentCallingStatus}?userUUID=${authUser.headers.userUUID}`,
                    {
                        headers: { ...authUser.headers },
                    }
                );

                if (response.data.responseMsg === "Success") {
                    setData(response.data.data.items);
                } else {
                    console.error('API Response Error:', response.data.responseMsg);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Report" breadcrumbItem="Verifier Wise Current Calling Status" />
                    <Card>
                        <CardBody>
                            {data && (
                                <div className='mt-3'>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <div className="flex-shrink-0">
                                            <Link
                                                to="#"
                                                // onClick={() => setModal(true)}
                                                className="btn btn-primary me-1"
                                            >
                                                Export Excel
                                            </Link>
                                        </div>
                                    </div>
                                    <TableContainer
                                        columns={columns}
                                        data={data || []}
                                        isGlobalFilter={true}
                                        isPagination={true}
                                        SearchPlaceholder="Search..."
                                        isCustomPageSize={true}
                                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        pagination="pagination"
                                        getRowProps={row => ({
                                            style: {
                                                color: row.original.isRecAvailable == 1 ? "red" : "transparent",
                                            },
                                        })}
                                        onPageChange={handlePageChange} // Pass page change handler
                                        onPageSizeChange={handlePageChange} // Pass page size change handler
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        totalPages={totalPages}
                                    />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default VerfierWiseCurrentCallingStatus
