import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  isUserLogout: false,
  userData: [],       // Store user data (e.g., user UUID, email, etc.)
  headers: {},    // Store access headers

};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: "",  // Reset any previous errors
     
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isUserLogout: false,
        userData: action.payload.userData,  // Store user data
        headers: action.payload.headers,  // Store access headers     
      };

    case LOGOUT_USER:
      return {
        ...state,
        loading: true,
        error: "",  // Reset any previous errors
      
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isUserLogout: true,
        userData: [],       // Clear user data on logout
        headers: {},    // Clear access headers   
      };

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };

    default:
      return state;
  }
};

export default login;


// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   error: "",
//   loading: false,
//   isUserLogout: false,
//   userData: null,       // Store user data (e.g., user UUID, email, etc.)
//   accessToken: null,    // Store access token
//   sessionKey: null,     // Store session key
//   clientUUID: null,     // Store client UUID
//   userUUID: null,       // Store user UUID
// };

// const loginSlice = createSlice({
//   name: 'login',
//   initialState,
//   reducers: {
//     // Action to initiate login process
//     loginUser: (state) => {
//       state.loading = true;
//       state.error = "";
//       state.userData = action.payload.userData;
//       state.accessToken = action.payload.accessToken;
//       state.sessionKey = action.payload.sessionKey;
//       state.clientUUID = action.payload.clientUUID;
//       state.userUUID = action.payload.userUUID;
//     },

//     // Action to handle successful login
//     loginSuccess: (state, action) => {
//       state.loading = false;
//       state.isUserLogout = false;
//       state.userData = action.payload.userData;
//       state.accessToken = action.payload.accessToken;
//       state.sessionKey = action.payload.sessionKey;
//       state.clientUUID = action.payload.clientUUID;
//       state.userUUID = action.payload.userUUID;
//     },

//     // Action to initiate logout process
//     logoutUser: (state) => {
//       state.loading = true;
//       state.error = "";
//       state.userData = null;
//       state.accessToken = null;
//       state.sessionKey = null;
//       state.clientUUID = null;
//       state.userUUID = null;
//     },

//     // Action to handle successful logout
//     logoutUserSuccess: (state) => {
//       state.isUserLogout = true;
//       state.userData = null;
//       state.accessToken = null;
//       state.sessionKey = null;
//       state.clientUUID = null;
//       state.userUUID = null;
//     },

//     // Action to handle API errors
//     apiError: (state, action) => {
//       state.error = action.payload;
//       state.loading = false;
//       state.isUserLogout = false;
//     },
//   },
// });

// // Export actions
// export const { loginUser, loginSuccess, logoutUser, logoutUserSuccess, apiError } = loginSlice.actions;

// // Export reducer
// export default loginSlice.reducer;

