// src/components/filter.
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

const Managerlivecallboard = () => {
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const columns = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Enquiries",
        accessorKey: "enq",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Work Orders",
        accessorKey: "workord",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Follow-Up ",
        accessorKey: "folow",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Lost",
        accessorKey: "lost",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      tele: "Anuja Pawar",
      enq: "09:01 AM",
      workord: "11",
      folow: "2",
      lost: "00:45",
    },
  ]

  const sysgencall = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Followup",
        accessorKey: "followup",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Work Orders",
        accessorKey: "workord",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Follow-Up ",
        accessorKey: "folow",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Lost",
        accessorKey: "lost",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const sysgencalldata = [
    {
      tele: "Anuja Pawar",
      enq: "09:01 AM",
      workord: "11",
      folow: "2",
      lost: "00:45",
    },
  ]

  const livecalling = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const livecallingdata = [
    {
      tele: "Anuja Pawar",
    },
  ]

  const syscalling = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const syscallingdata = [
    {
      tele: "Anuja Pawar",
    },
  ]
  const sysgenfeedcall = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Feedback Calls",
        accessorKey: "feedcall",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Not required now for development",
        accessorKey: "develop",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link to="#" className="btn btn-sm btn-soft-primary">
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const sysgenfeedcalldata = [
    {
      tele: "Anuja Pawar",
      feedcall: "09:01 AM",
      develop: "11",
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Live Callboard" />
        <Card>
          <CardBody className="border-bottom">
            <CardTitle className="mb-4">Live Call Distribution</CardTitle>

            <Row>
              <Col md={8}>
                {" "}
                <TableContainer
                  columns={columns}
                  data={data || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
              <Col md={4}>
                {" "}
                <TableContainer
                  columns={livecalling}
                  data={livecallingdata || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            {/* <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div> */}

            <CardTitle className="mb-4">
              System Generated Call Distribution
            </CardTitle>

            <Row>
              <Col md={8}>
                {" "}
                <TableContainer
                  columns={columns}
                  data={data || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
              <Col md={4}>
                {" "}
                <TableContainer
                  columns={livecalling}
                  data={livecallingdata || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            <CardTitle className="mb-4">
              Confirmation Call Distribution
            </CardTitle>

            <Row>
              <Col md={8}>
                {" "}
                <TableContainer
                  columns={columns}
                  data={data || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
              <Col md={4}>
                {" "}
                <TableContainer
                  columns={livecalling}
                  data={livecallingdata || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            <CardTitle className="mb-4">
              System Generated Cold Calls Leads Distribution
            </CardTitle>

            <Row>
              <Col md={8}>
                {" "}
                <TableContainer
                  columns={columns}
                  data={data || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
              <Col md={4}>
                {" "}
                <TableContainer
                  columns={livecalling}
                  data={livecallingdata || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            <CardTitle className="mb-4">
              System Generated Feedback Calls Distribution
            </CardTitle>

            <Row>
              <Col md={5}>
                {" "}
                <TableContainer
                  columns={sysgenfeedcall}
                  data={sysgenfeedcalldata || []}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Managerlivecallboard.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Managerlivecallboard
