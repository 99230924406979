// src/components/filter.
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

const Mangaerblockedcustomerlist = () => {
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const columns = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "tele",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Login Time",
        accessorKey: "logtime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Attended Calls",
        accessorKey: "attendcalls",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "No of Breaks",
        accessorKey: "breaks",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Working Time In Mins",
        accessorKey: "worktime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Current Status",
        accessorKey: "cursta",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Break Since",
        accessorKey: "breaks",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      tele: "Anuja Pawar",
      logtime: "09:01 AM",
      attendcalls: "11",
      breaks: "2",
      worktime: "00:45",
      cursta: "Active ",
      breaks: "15 Mins",
    },
  ]

  const pendingcalls = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "telecaller",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Enquiry Pending",
        accessorKey: "enqpen",

        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Enquiry Action",
        accessorKey: "enqact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Follow Up Pending",
        accessorKey: "followpen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Follow Up Action",
        accessorKey: "followact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Old Cutomer Pending",
        accessorKey: "oldcustpen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Old Cutomer Action",
        accessorKey: "oldcustact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "In Guarantee Pending",
        accessorKey: "guarupen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "In Guarantee Action",
        accessorKey: "guaruact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "W/O Placed Pending",
        accessorKey: "woplcepen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Placed Action",
        accessorKey: "woplceact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Todya's W/O Pending",
        accessorKey: "todaypen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Todya's W/O  Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
              />
            </div>
          )
        },
      },
    ],
    []
  )

  const pendingcallsdata = [
    {
      telecaller: "Anuja Pawar",
      enqpen: "7",
      followpen: "7",
      oldcustpen: "7",
      guarupen: "7",
      woplcepen: "7",
      todaypen: "7",
    },
  ]

  const systemcalls = useMemo(
    () => [
      {
        header: "Telecaller",
        accessorKey: "telecaller",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Follow Up Pending",
        accessorKey: "followpen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Follow Up Action",
        accessorKey: "followact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Confirmation Pending",
        accessorKey: "confpen",

        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Confirmation Action",
        accessorKey: "enqact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },

      {
        header: "Recalling Pending",
        accessorKey: "recallpen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Recalling Action",
        accessorKey: "oldcustact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Guarantee Pending",
        accessorKey: "guarupen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Guarantee Action",
        accessorKey: "guaruact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Feedback Pending",
        accessorKey: "feedpen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Feedback Action",
        accessorKey: "woplceact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },
      {
        header: "Cold Calling Pending",
        accessorKey: "coldpen",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Todya's W/O  Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
              />
            </div>
          )
        },
      },
    ],
    []
  )

  const systemcallsdata = [
    {
      telecaller: "Anuja Pawar",
      enqpen: "7",
      followpen: "7",
      confpen: "7",
      recallpen: "7",
      feedpen: "7",
      coldpen: "7",
    },
  ]

  const dashboardcalls = useMemo(
    () => [
      {
        header: "Telecaller Name",
        accessorKey: "telecaller",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Live",
        accessorKey: "followact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked
                onClick={e => {
                  settoggleSwitch(!toggleSwitch)
                }}
              />
            </div>
          )
        },
      },

      {
        header: "System Generated",
        accessorKey: "enqact",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
              />
            </div>
          )
        },
      },
    ],
    []
  )

  const dashboardcallsdata = [
    {
      telecaller: "Anuja Pawar",
    },
    {
      telecaller: "Tejas Shinde",
    },
    {
      telecaller: "Rahul Pawar",
    },
  ]
  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title="Tables"
          breadcrumbItem="Blocked Customer Missed Call List"
        />
        <Card>
          <CardBody className="border-bottom">
            {/* <CardTitle className="mb-4">Active Product</CardTitle> */}

            <TableContainer
              columns={columns}
              data={data || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search..."
              isCustomPageSize={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            {/* <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div> */}

            {/* <CardTitle className="mb-4">Delivered Product</CardTitle> */}

            <TableContainer
              columns={columns}
              data={data || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search..."
              isCustomPageSize={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
        <CardTitle className="mb-4">Pending At Live</CardTitle>
        <Row>
          <Col lg={4}>
            <Card key="">
              <CardBody>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title mt-2 mb-3">W/O Placed</h4>
                  <div className="flex-shrink-0">
                    <div className="btn btn-primary me-1">Present Calls 7</div>
                  </div>
                </div>
                <div className="table-responsive tab-overflow">
                  <table className="table table-nowrap align-middle mb-0">
                    <tbody>
                      {/* {item.cards.map((card, index) => ( */}
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Anuja Pawar
                            </Link>
                          </h5>
                        </td>

                        <td>
                          <Badge pill className="badge-soft-success ms-1">
                            Active
                          </Badge>
                        </td>
                      </tr>
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Out of town
                            </Link>
                          </h5>
                        </td>

                        <td>
                          <Badge pill className="badge-soft-dark ms-1">
                            Break
                          </Badge>
                        </td>
                      </tr>
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Let me decide
                            </Link>
                          </h5>
                        </td>
                        <td>
                          <Badge pill className="badge-soft-danger ms-1">
                            Absent
                          </Badge>
                        </td>
                      </tr>
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Test Test
                            </Link>
                          </h5>
                        </td>

                        <td>
                          <td>
                            <Badge pill className="badge-soft-dark ms-1">
                              Break
                            </Badge>
                          </td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card key="">
              <CardBody>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title mt-2 mb-3">New Enquiry</h4>
                  <div className="flex-shrink-0">
                    <div className="btn btn-primary me-1">Present Calls 7</div>
                  </div>
                </div>
                <div className="table-responsive tab-overflow">
                  <table className="table table-nowrap align-middle mb-0">
                    <tbody>
                      {/* {item.cards.map((card, index) => ( */}
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Anuja Pawar
                            </Link>
                          </h5>
                        </td>

                        <td>
                          <Badge pill className="badge-soft-success ms-1">
                            Active
                          </Badge>
                        </td>
                      </tr>
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Out of town
                            </Link>
                          </h5>
                        </td>

                        <td>
                          <Badge pill className="badge-soft-dark ms-1">
                            Break
                          </Badge>
                        </td>
                      </tr>
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Let me decide
                            </Link>
                          </h5>
                        </td>
                        <td>
                          <Badge pill className="badge-soft-danger ms-1">
                            Absent
                          </Badge>
                        </td>
                      </tr>
                      <tr key="">
                        <td>
                          <h5 className="text-truncate font-size-14 m-0">
                            <Link to="#" className="text-dark">
                              Test Test
                            </Link>
                          </h5>
                        </td>

                        <td>
                          <td>
                            <Badge pill className="badge-soft-dark ms-1">
                              Break
                            </Badge>
                          </td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CardTitle className="mb-4">Live Pending Calls (Total 18)</CardTitle>
        <Row>
          <Col lg={12}>
            <Card key="">
              <CardBody className="border-bottom">
                {/* <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div> */}

                <CardTitle className="mb-4">Delivered Product</CardTitle>

                <TableContainer
                  columns={pendingcalls}
                  data={pendingcallsdata || []}
                  isGlobalFilter={true}
                  isPagination={true}
                  SearchPlaceholder="Search..."
                  isCustomPageSize={true}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                  paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  pagination="pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CardTitle className="mb-4">System Generated Pending Calls</CardTitle>
        <Row>
          <Col lg={12}>
            <Card key="">
              <CardBody className="border-bottom">
                {/* <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div> */}

                <CardTitle className="mb-4">Delivered Product</CardTitle>

                <TableContainer
                  columns={systemcalls}
                  data={systemcallsdata || []}
                  isGlobalFilter={true}
                  isPagination={true}
                  SearchPlaceholder="Search..."
                  isCustomPageSize={true}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                  paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  pagination="pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CardTitle className="mb-4">Dashboard Calling Toggles</CardTitle>
        <Row>
          <Col lg={4} offset={4}>
            <Card key="">
              <CardBody className="border-bottom">
                {/* <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div> */}

                {/* <CardTitle className="mb-4">Delivered Product</CardTitle> */}

                <TableContainer
                  columns={dashboardcalls}
                  data={dashboardcallsdata || []}
                  // isGlobalFilter={true}
                  // isPagination={true}
                  // SearchPlaceholder="Search..."
                  // isCustomPageSize={true}
                  tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light"
                  // paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  // pagination="pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
Mangaerblockedcustomerlist.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Mangaerblockedcustomerlist
