import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container, Input, UncontrolledTooltip } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from 'components/Common/TableContainer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getUserForChangeCallAvailabilityAPIURL, getUserListForCallAvailabilityAPIURL, updateUserForCallAvailabilityAPIURL } from 'BaseComponentAPI/CallAvailability';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';

const ChangeUserCallAvailability = () => {
    const authUser = useSelector(state => state.Login);
    // console.log(JSON.stringify(authUser))

    const navigate = useNavigate();

    document.title = "Dashboard | Skote - React Admin & Dashboard Template";

    const { empId } = useParams();

    const handleCheckboxChange = (rowData, fieldName, e) => {
        const newData = [rowData]; // You should not create a new array like this. It's better to work with the state directly (filteredData).
        // console.log(e.target.checked); // Log the checkbox status (true or false)

        const updatedData = newData.map(item =>
            item.userUUID === rowData.userUUID
                ? {
                    ...item,
                    [fieldName]: e.target.checked ? 1 : 0 // If checked, set to 1, else set to 0
                }
                : item
        );

        // console.log(JSON.stringify(updatedData)); // Log updated data

        // Optionally, update the state if needed
        setData(updatedData);  // Don't forget to use state update if you're managing the state
    };



    const columns = useMemo(
        () => [
            {
                header: "User Name",
                accessorKey: "userName",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "User Role",
                accessorKey: "userRole",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Department",
                accessorKey: "department",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Is Available For Calling",
                accessorKey: "isAvailable",
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ cell }) => (
                    <Input
                        type="checkbox"
                        defaultChecked={cell.getValue() == 1}
                        // value={cell.row.original.isAvailable}
                        onChange={(e) => handleCheckboxChange(cell.row.original, "isAvailable", e)}
                    />
                ),
            },
            {
                header: "Is Live Access",
                accessorKey: "isLiveAccess",
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ cell }) => (
                    <Input
                        type="checkbox"
                        defaultChecked={cell.getValue() == 1}
                        // value={cell.row.original.isLiveAccess}
                        onChange={(e) => handleCheckboxChange(cell.row.original, "isLiveAccess", e)}
                    />
                ),
            },
            {
                header: "Is System Access",
                accessorKey: "isSystemAccess",
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ cell }) => (
                    <Input
                        type="checkbox"
                        defaultChecked={cell.getValue() == 1}
                        // value={cell.row.original.isSystemAccess}
                        onChange={(e) => handleCheckboxChange(cell.row.original, "isSystemAccess", e)}
                    />
                ),
            },
            {
                header: "Is Cold Call Access",
                accessorKey: "isColdCallAccess",
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ cell }) => (
                    <Input
                        type="checkbox"
                        defaultChecked={cell.getValue() == 1}
                        // value={cell.row.original.isColdCallAccess}
                        onChange={(e) => handleCheckboxChange(cell.row.original, "isColdCallAccess", e)}
                    />
                ),
            },
            {
                header: "Action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            <button
                                className="btn btn-sm btn-success"
                                onClick={() => {
                                    handleChangeButtonClick(cellProps.row.original);
                                }}
                            >
                                Change
                            </button>
                        </li>
                    </ul>
                ),
            },
        ],
        []
    );


    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [pageNo, setPageNo] = useState(1);  // Track the current page number
    const [pageSize, setPageSize] = useState(10);  // Track page size
    const [totalPages, setTotalPages] = useState('');

    const handlePageChange = (newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
    };

    useEffect(() => {
        getData();
    }, [authUser, empId]);

    const getData = async () => {
        if (authUser.headers && Object.keys(authUser.headers).length > 0) {
            // console.log(`API URL: ${getUserForChangeCallAvailabilityAPIURL}?employeeUUID=${empId}`);

            try {
                const response = await axios.get(
                    `${getUserForChangeCallAvailabilityAPIURL}?employeeUUID=${empId}`,
                    {
                        headers: { ...authUser.headers },
                    }
                );
                // console.log(JSON.stringify(response.data.data))
                if (response.data.responseMsg === "Success") {
                    setData([response.data.data]);
                    setFilteredData([response.data.data])
                } else {
                    console.error('API Response Error:', response.data.responseMsg);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    const handleChangeButtonClick = async (rowData) => {
        const payload = {
            employeeUUID: empId,
            isAvailable: rowData.isAvailable,
            isLiveAccess: rowData.isLiveAccess,
            isSystemAccess: rowData.isSystemAccess,
            isColdCallAccess: rowData.isColdCallAccess,
        };



        // if (authUser.headers && Object.keys(authUser.headers).length > 0) {
        // console.log(updateUserForCallAvailabilityAPIURL)
        try {
            const response = await axios.patch(`${updateUserForCallAvailabilityAPIURL}`, payload,
                {
                    headers: { ...authUser.headers },
                }
            );

            if (response.data.responseMsg === "Success") {
                // alert('Changes updated successfully!');
                toast.success("Changes updated successfully!")
                navigate('/callAvailability');
            } else {
                console.log('Failed to update changes: ' + response.data.responseMsg);
            }
        } catch (error) {
            console.error('Error updating data:', error);
            console.log('An error occurred while updating changes.');
        }
        // }
    };

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Users" breadcrumbItem="Change Call Availability" />
                        <Card>
                            <CardBody>
                                {data && (
                                    <div className='mt-3'>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className="flex-shrink-0">
                                                <Link
                                                    to="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate('/callAvailability')
                                                    }}
                                                    className="btn btn-danger me-1"
                                                >
                                                    Back
                                                </Link>
                                            </div>
                                        </div>
                                        <TableContainer
                                            columns={columns}
                                            data={data || []}
                                            isGlobalFilter={true}
                                            isPagination={true}
                                            SearchPlaceholder="Search..."
                                            isCustomPageSize={true}
                                            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                            theadClass="table-light"
                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            pagination="pagination"
                                            getRowProps={row => ({
                                                style: {
                                                    color: row.original.isRecAvailable == 1 ? "red" : "transparent",
                                                },
                                            })}
                                            onPageChange={handlePageChange} // Pass page change handler
                                            onPageSizeChange={handlePageChange} // Pass page size change handler
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            totalPages={totalPages}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                </div>


            </React.Fragment>

        </>

    )
}


ChangeUserCallAvailability.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ChangeUserCallAvailability);
