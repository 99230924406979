import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Container, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import TableContainer from 'components/Common/TableContainer';
import { json, Link } from 'react-router-dom';
import { getCurrentCallBoardStatusAPIURL } from 'BaseComponentAPI/CurrentCallBoardStatusAPI';
import { useSelector } from 'react-redux';
import axios from 'axios';

const CurrentCallBoardStatus = (props) => {
    const authUser = useSelector(state => state.Login);
    document.title = "Dashboard | Skote - React Admin & Dashboard Template";

    const columns = useMemo(
        () => [
            {
                header: "Sr No",
                accessorKey: "seqNo",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Type",
                accessorKey: "callType",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Status",
                accessorKey: "callStatus",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Customer Name",
                accessorKey: "customerName",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Mobile Number",
                accessorKey: "customerMobile",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Time",
                accessorKey: "callReceivedTime",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Header",
                accessorKey: "header",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Verification Starts At",
                accessorKey: "verifierStartsAt",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Disposition",
                accessorKey: "disposition",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Verifier Call End Time",
                accessorKey: "verifierEndAt",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Product",
                accessorKey: "product",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Subcategory",
                accessorKey: "subCategory",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Category",
                accessorKey: "category",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "TC Call Time ",
                accessorKey: "tcCallAt ",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "TC Name",
                accessorKey: "tcName",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Future Header",
                accessorKey: "futureHeader",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Tc Call End Time",
                accessorKey: "tcCallendAt",
                enableColumnFilter: false,
                enableSorting: true,
            },

            //   {
            //     header: "Action",
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     cell: cellProps => {
            //       return (
            //         <ul className="list-unstyled hstack gap-1 mb-0">
            //           <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
            //             <Link
            //               to="/job-details"
            //               className="btn btn-sm btn-soft-primary"
            //               id={`viewtooltip-${cellProps.row.original.id}`}
            //             >
            //               <i className="mdi mdi-eye-outline" />
            //             </Link>
            //           </li>
            //           <UncontrolledTooltip
            //             placement="top"
            //             target={`viewtooltip-${cellProps.row.original.id}`}
            //           >
            //             View
            //           </UncontrolledTooltip>

            //           <li>
            //             <Link
            //               to="/createoredituser"
            //               className="btn btn-sm btn-soft-info"
            //             >
            //               <i className="mdi mdi-pencil-outline" />
            //             </Link>
            //           </li>

            //           <li>
            //             <Link
            //               to="#"
            //               className="btn btn-sm btn-soft-danger"
            //               onClick={() => {
            //                 const jobData = cellProps.row.original
            //                 onClickDelete(jobData)
            //               }}
            //               id={`deletetooltip-${cellProps.row.original.id}`}
            //             >
            //               <i className="mdi mdi-delete-outline" />
            //               <UncontrolledTooltip
            //                 placement="top"
            //                 target={`deletetooltip-${cellProps.row.original.id}`}
            //               >
            //                 Delete
            //               </UncontrolledTooltip>
            //             </Link>
            //           </li>
            //         </ul>
            //       )
            //     },
            //   },
        ],
        []
    )

    const [data, setData] = useState([]);
    const [submittedCallType, setSubmittedCallType] = useState("");
    const [pageNo, setPageNo] = useState(1);  // Track the current page number
    const [pageSize, setPageSize] = useState(10);  // Track page size
    const [totalPages, setTotalPages] = useState('');

    const handlePageChange = (newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
    };

    const formik = useFormik({
        initialValues: {
            callType: ''
        },
        validationSchema: Yup.object({
            callType: Yup.string()
                .required('Call Type is required'),
        }),

        onSubmit: async (values) => {

            // Store the submitted call type for display in the heading
            const callTypeLabelMap = {
                "0": "All",
                "1": "Live",
                "2": "System",
                "3": "Cold",
            };
            setSubmittedCallType(callTypeLabelMap[values.callType]);

            if (authUser.headers && Object.keys(authUser.headers).length > 0) {
                // console.log(`API URL: ${getCurrentCallBoardStatusAPIURL}?userUUID=${authUser.headers.userUUID}&callType=${values.callType}&isPagination=0&pageNo=0&perPageRec=0`);

                try {
                    const response = await axios.get(
                        `${getCurrentCallBoardStatusAPIURL}?userUUID=${authUser.headers.userUUID}&callType=${values.callType}&isPagination=1&pageNo=${pageNo}&perPageRec=${pageSize}`,
                        {
                            headers: { ...authUser.headers },
                        }
                    );

                    if (response.data.responseMsg == "Success") {
                        // console.log(JSON.stringify(response.data))
                        setData(response.data.data.items);
                        setTotalPages(response.data.data.totalPages)
                    } else {
                        console.error('API Response Error:', response.data.responseMsg);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Report" breadcrumbItem="Current Call Board Status" />
                    <Card>
                        <CardBody>

                            <Form onSubmit={formik.handleSubmit}>

                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="callType">Call Type</Label>
                                            <Input
                                                type="select"
                                                name="callType"
                                                id="callType"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.callType}
                                            >
                                                <option value="">Select Call Type</option>
                                                <option value={0}>All</option>
                                                <option value={1}>Live</option>
                                                <option value={2}>System</option>
                                                <option value={3}>Cold</option>

                                            </Input>
                                            {formik.touched.callType && formik.errors.callType ? (
                                                <div style={{ color: 'red', marginTop: '5px' }}>
                                                    {formik.errors.callType}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Button color="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>

                            {/* Conditional Heading */}
                            {submittedCallType && (
                                <h5 className="mt-4" style={{ color: '#b72323' }}>
                                    Report for : Current {submittedCallType} Call Status
                                </h5>
                            )}

                            {submittedCallType && (
                                <div className='mt-3'>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <div className="flex-shrink-0">
                                            <Link
                                                to="#"
                                                // onClick={() => setModal(true)}
                                                className="btn btn-primary me-1"
                                            >
                                                Export Excel
                                            </Link>
                                        </div>
                                    </div>
                                    <TableContainer
                                        columns={columns}
                                        data={data || []}
                                        isGlobalFilter={true}
                                        isPagination={true}
                                        SearchPlaceholder="Search..."
                                        isCustomPageSize={true}
                                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        pagination="pagination"
                                        getRowProps={row => ({
                                            style: {
                                                color: row.original.currentStatus == 1 ? "red" : "transparent",
                                            },
                                        })}
                                        onPageChange={handlePageChange} // Pass page change handler
                                        onPageSizeChange={handlePageChange} // Pass page size change handler
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        totalPages={totalPages}
                                    />
                                </div>
                            )}

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

CurrentCallBoardStatus.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(CurrentCallBoardStatus);
