import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container, Input, Button, Label, Row, Col, Form, FormGroup } from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { getActiveUserListAPIURL, getUserWiseDurationReportAPIURL } from 'BaseComponentAPI/UserWiseDurationReportAPI';
import { getUserListForCallAvailabilityAPIURL } from 'BaseComponentAPI/CallAvailability';
import axios from 'axios';
import { useFormik } from 'formik';
import TableContainer from 'components/Common/TableContainer';
import * as Yup from 'yup';
import Select from 'react-select';
import moment from 'moment';


const UserWiseDurationReport = () => {

    const authUser = useSelector(state => state.Login);
    document.title = "Dashboard | Skote - React Admin & Dashboard Template";

    const columns = useMemo(
        () => [
            {
                header: "Activity Date & Time",
                accessorKey: "activityDateTime",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "User Activity",
                accessorKey: "userActivity",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Mobile Number",
                accessorKey: "mobileNo",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Talktime Duration",
                accessorKey: "talktimeDuration",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Call Type",
                accessorKey: "callType",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => {
                    const callType = cell.getValue();
                    switch (callType) {
                        case 1:
                            return "Live";
                        case 2:
                            return "System";
                        case 3:
                            return "Cold";
                        case 0:
                        default:
                            return "All";
                    }
                },
            },
            {
                header: "Is Customer / Is Lead",
                accessorKey: "isLeadOrCustomer",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Is Open Enquiry",
                accessorKey: "isOpenEnquiry",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Is Included In Call Duration",
                accessorKey: "isIncludedAtDuration",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ cell }) => (cell.getValue() === 1 ? "Yes" : "No"),
            },
            {
                header: "Break Duration",
                accessorKey: "breakTime",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Disposition",
                accessorKey: "callDisposition",
                enableColumnFilter: false,
                enableSorting: true,
            },

        ],
        []
    );


    const [data, setData] = useState([]);
    const [submittedCallType, setSubmittedCallType] = useState("");
    const [pageNo, setPageNo] = useState(1);  // Track the current page number
    const [pageSize, setPageSize] = useState(10);  // Track page size
    const [totalPages, setTotalPages] = useState('');
    const [employeeData, setEmployeeData] = useState([]);
    const [employeeId, setEmployeeId] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // Convert employees array to the format that react-select needs
    const employeeOptions = employeeData.map(emp => ({
        value: emp.userUUID,
        label: emp.userName
    }))

    // Handle the change for the employee ID
    const handleEmployeeIdChange = (e) => {
        setEmployeeId(e.target.value);
    }

    // Handle date change (for both fromDate and toDate)
    const handleDateChange = (date, field) => {
        formik.setFieldValue(field, date);
    };

    useEffect(() => {
        getData();
    }, [authUser, pageNo, pageSize]);


    const getData = async () => {
        if (authUser.headers && Object.keys(authUser.headers).length > 0) {


            try {
                const response = await axios.get(
                    `${getActiveUserListAPIURL}?userUUID=${authUser.headers.userUUID}&isPagination=0&pageNo=0&perPageRec=0`,
                    {
                        headers: { ...authUser.headers },
                    }
                );

                if (response.data.responseMsg === "Success") {
                    setEmployeeData(response.data.data.items);
                    setTotalPages(response.data.data.totalPages)
                }
                else {
                    console.error('API Response Error:', response.data.responseMsg);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const handlePageChange = (newPageNo, newPageSize) => {
        setPageNo(newPageNo);
        setPageSize(newPageSize);
    };

    const formik = useFormik({
        initialValues: {
            employeeId: '',  // Employee selection field
            fromDate: null,
            toDate: null
        },
        validationSchema: Yup.object({
            employeeId: Yup.string()
                .required('Employee is required'),  // This makes employeeId a required field
            fromDate: Yup.string()
                .required('From Date is required'),
            toDate: Yup.string()
                .required('To Date is required'),
        }),

        onSubmit: async (values) => {

            if (authUser.headers && Object.keys(authUser.headers).length > 0) {
                // console.log(`API URL: ${getUserWiseDurationReportAPIURL}?employeeUUID=${values.employeeId}&startDate=${moment(values.fromDate).format("DD/MM/YYYY")}&endDate=${moment(values.toDate).format("DD/MM/YYYY")}&pageNo=${pageNo}&perPageRec=${pageSize}`);

                try {
                    const response = await axios.get(
                        `${getUserWiseDurationReportAPIURL}?employeeUUID=${values.employeeId}&startDate=${moment(values.fromDate).format("DD/MM/YYYY")}&endDate=${moment(values.toDate).format("DD/MM/YYYY")}&pageNo=${pageNo}&perPageRec=${pageSize}`,
                        {
                            headers: { ...authUser.headers },
                        }
                    );

                    if (response.data.responseMsg === "Success") {
                        setData(response.data.data.items);
                        setTotalPages(response.data.data.totalPages)
                    }
                    else {
                        console.error('API Response Error:', response.data.responseMsg);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

        },
    });

    const handleEmployeeChange = (selectedOption) => {
        const selectedEmployeeId = selectedOption ? selectedOption.value : '';
        formik.setFieldValue('employeeId', selectedEmployeeId);  // Set employeeId in formik state
    };




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Report" breadcrumbItem="User wise Duration Report" />
                    <Card>
                        <CardBody className="border-bottom">
                            <div style={{ padding: '20px' }}>

                                <Form onSubmit={formik.handleSubmit}>
                                    <Row>
                                        {/* Employee Select */}
                                        <Col md="4">
                                            <FormGroup>
                                                <Label for="employeeId">Employee</Label>
                                                <Select
                                                    id="employeeId"
                                                    name="employeeId"
                                                    className="form-control-md"
                                                    options={employeeOptions}
                                                    value={employeeOptions.find(option => option.value === formik.values.employeeId)}
                                                    onChange={handleEmployeeChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Select Employee"
                                                />
                                                {formik.touched.employeeId && formik.errors.employeeId && (
                                                    <div style={{ color: 'red', marginTop: '5px' }}>
                                                        {formik.errors.employeeId}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row className='my-3'>
                                        {/* From Date Picker */}
                                        <Col md="4">
                                            <FormGroup>
                                                <Label for="fromDate">From Date</Label>
                                                <DatePicker
                                                    selected={formik.values.fromDate}
                                                    onChange={(date) => handleDateChange(date, 'fromDate')}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Select From Date"
                                                    popperPlacement="bottom-start"
                                                />
                                                {formik.touched.fromDate && formik.errors.fromDate && (
                                                    <div style={{ color: 'red', marginTop: '5px' }}>
                                                        {formik.errors.fromDate}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        {/* To Date Picker */}
                                        <Col md="4">
                                            <FormGroup>
                                                <Label for="toDate">To Date</Label>
                                                <DatePicker
                                                    selected={formik.values.toDate}
                                                    onChange={(date) => handleDateChange(date, 'toDate')}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    placeholderText="Select To Date"
                                                    popperPlacement="bottom-start"
                                                />
                                                {formik.touched.toDate && formik.errors.toDate && (
                                                    <div style={{ color: 'red', marginTop: '5px' }}>
                                                        {formik.errors.toDate}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        {/* Submit Button */}
                                        <Col md="12">
                                            <Button color="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {
                                data.length != 0
                                    ?
                                    <div className='mt-3'>
                                        {/* <div className="d-flex align-items-center justify-content-end">
                                        <div className="flex-shrink-0">
                                            <Link
                                                to="#"
                                                // onClick={() => setModal(true)}
                                                className="btn btn-primary me-1"
                                            >
                                                Export Excel
                                            </Link>
                                        </div>
                                    </div> */}
                                        <TableContainer
                                            columns={columns}
                                            data={data || []}
                                            isGlobalFilter={true}
                                            isPagination={true}
                                            SearchPlaceholder="Search..."
                                            isCustomPageSize={true}
                                            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                            theadClass="table-light"
                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            pagination="pagination"
                                            getRowProps={row => ({
                                                style: {
                                                    color: row.original.isRecAvailable == 1 ? "red" : "transparent",
                                                },
                                            })}
                                            onPageChange={handlePageChange} // Pass page change handler
                                            onPageSizeChange={handlePageChange} // Pass page size change handler
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            totalPages={totalPages}
                                        />
                                    </div>
                                    :
                                    ''
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

UserWiseDurationReport.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(UserWiseDurationReport);
