import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table,
    Card,
    CardBody,
} from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { uploadColdAllAPIURL } from 'BaseComponentAPI/ColdCallUplaodAPI';
import { toast } from 'react-toastify';


const ColdCallUploads = (props) => {
    const navigate = useNavigate();
    const authUser = useSelector(state => state.Login);

    document.title = "Dashboard | Skote - React Admin & Dashboard Template";

    const [file, setFile] = useState(null); // State for storing the uploaded file
    const [clientId, setClientId] = useState(null); // State for storing clientId
    const [userId, setUserId] = useState(null); // State for storing userId
    const [headerData, setHeaderData] = useState({});

    const [withDataFiles, setWithDataFiles] = useState([]); // State for storing WithData files
    const [withoutDataFiles, setWithoutDataFiles] = useState([]); // State for storing WithoutData files

    // Use useEffect to fetch session data and update state once
    useEffect(() => {

        // Assuming the files are stored in your public folder
        // Replace the following paths with your actual file paths
        setWithDataFiles([
            'assets/ColdCallUploadExcelFiles/WithData/SampleFile1.xlsx',
        ]);

        setWithoutDataFiles([
            'assets/ColdCallUploadExcelFiles/WithoutData/SampleFile1.xlsx',
        ]);
    }, []);

    const formik = useFormik({
        initialValues: {
            campaignName: '',
            campaignDesc: '',
            isWithData: '', // Initially empty for radio button selection
            file: null,
        },
        validationSchema: Yup.object({
            campaignName: Yup.string()
                .required('Campaign Name is required'),
            campaignDesc: Yup.string().required('Campaign Description is required'),
            isWithData: Yup.string().required('Please select an option for data inclusion'),
            file: Yup.mixed()
                .test('fileSize', 'File size should not exceed 1MB', (value) => {
                    if (value) {
                        return value.size <= 1048576; // 1MB in bytes
                    }
                    return true; // No file selected
                })
                .required('Please upload a file'),
        }),

        onSubmit: async (values) => {

            if (file && file.size > 1048576) {
                // Check if file size is greater than 1MB
                // console.error("Missing clientId or userId");
                alert('File size exceeds 1MB. Please upload a smaller file.');
                return;
            }

            // Check the file size before proceeding with the API call
            if (authUser.headers != {}) {
                // console.log(values.isWithData)
                // If the file size is within limit, proceed with the API request
                const formData = new FormData();
                formData.append('clientUUID', authUser.headers.clientUUID);
                formData.append('userUUID', authUser.headers.userUUID);
                formData.append('campaignName', values.campaignName);
                formData.append('campaignDesc', values.campaignDesc);
                formData.append('isWithData', values.isWithData === 'withData' ? 1 : 0);
                if (file) formData.append('File', file);

                // Print the FormData contents for debugging
                // formData.forEach((value, key) => {
                //     console.log(`${key}: ${value}`);
                // });

                try {
                    const response = await axios.post(uploadColdAllAPIURL, formData, {
                        headers: {
                            ...authUser.headers,
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    // console.log('Response:', response.data);
                    if (response.data != null) {
                        toast.success("Cold Call File Upload Successfully done.")
                    }



                } catch (error) {
                    console.error('Error:', error);
                }
            }
        },
    });


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        formik.setFieldValue("file", selectedFile);
    };

    const renderDownloadLinks = (files) => {
        return files.map((file, index) => (

            <span key={index}>
                <a href={`/${file}`} download>{`${file.split('/').pop()}`}</a>
            </span>

        ));
    };

    const handleClearAllFields = () => {
        formik.resetForm();
        formik.setFieldValue("isWithData", '');
        setFile(null);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Cold Calls" breadcrumbItem="Upload Calls" />
                    <Card>
                        <CardBody className="border-bottom">
                            <div style={{ padding: '20px' }}>
                                <Form onSubmit={formik.handleSubmit}>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="isWithData">Data Inclusion</Label>
                                                <div className="d-flex">
                                                    <FormGroup className="me-3"> {/* Add margin for spacing */}
                                                        <Label>
                                                            <Input
                                                                type="radio"
                                                                name="isWithData"
                                                                value={formik.values.isWithData == "withData"}
                                                                onChange={formik.handleChange}
                                                            />
                                                            {' '}
                                                            With Data
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>
                                                            <Input
                                                                type="radio"
                                                                name="isWithData"
                                                                value={formik.values.isWithData == "withoutData"}
                                                                onChange={formik.handleChange}
                                                            />
                                                            {' '}
                                                            Without Data
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                {formik.touched.isWithData && formik.errors.isWithData ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.isWithData}</div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="campaignName">Campaign Name</Label>
                                                <Input
                                                    id="campaignName"
                                                    name="campaignName"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.campaignName}
                                                />
                                                {formik.touched.campaignName && formik.errors.campaignName ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.campaignName}</div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="campaignDesc">Campaign Description</Label>
                                                <Input
                                                    id="campaignDesc"
                                                    name="campaignDesc"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.campaignDesc}
                                                />
                                                {formik.touched.campaignDesc && formik.errors.campaignDesc ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.campaignDesc}</div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="file">Upload Excel File</Label>
                                                <Input
                                                    id="file"
                                                    name="file"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    accept=".xls, .xlsx"
                                                />
                                                {formik.touched.file && formik.errors.file ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.file}</div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div className='mt-3'>
                                        <Button type="submit" color="primary" className="w-md">
                                            Submit
                                        </Button>
                                        <Button type="button" color="danger" className="w-md ms-2" onClick={handleClearAllFields}>
                                            Clear
                                        </Button>
                                    </div>
                                </Form>

                                <hr />

                                {/* Table Section */}
                                <div className="mt-4">
                                    <Row>
                                        <Col md={2}>
                                            <h5 style={{ fontSize: '15px', color: '#000' }}>With Data Files</h5>
                                            <div className="pt-2 pb-2">
                                                {renderDownloadLinks(withDataFiles)}
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <h5 style={{ fontSize: '15px', color: '#000' }}>Without Data Files</h5>
                                            <div className="pt-2 pb-2">
                                                {renderDownloadLinks(withoutDataFiles)}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

ColdCallUploads.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ColdCallUploads);
